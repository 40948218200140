"use client";

// Libraries
import Head from "next/head";
import { useForm } from "react-hook-form";
import { Link, useRouter } from "@/lib/i18n";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { signIn, useSession } from "next-auth/react";
import * as m from "@/paraglide/messages";
import { languageTag } from "@/paraglide/runtime";
import Logo from "@/components/ui/Logo/Logo";

// Components
import Layout from "@/components/ui/Layout/Layout";
import Button from "@/components/ui/Button/Button";
import Lottie from "lottie-react";
import hero from "@/public/static/animations/auth.json";
import { useSearchParams } from "next/navigation";

export default function Login({ session, ...props }) {
    // Variables
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const router = useRouter();
    const searchParams = useSearchParams();
    const firstName = searchParams.get("firstName");
    const queryError = searchParams.get("error");
    const redirect = searchParams.get("redirect");

    if (session) {
        router.push("/");
    }

    // States
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();

    // Methods
    const onSubmit = async (data) => {
        if (!isLoading) {
            setIsLoading(true);

            const response = await signIn("credentials", {
                email: data.email,
                password: data.password,
                redirect: false,
            });

            setIsLoading(false);

            if (response.error) {
                toast.error(response.error);
            } else {
                if (firstName) {
                    toast.success(m.welcome_back(firstName));
                } else {
                    toast.success(m.login_connected());
                }
                // If redirect is set in url, redirect to it
                if (redirect) {
                    if (redirect == "/mentor") {
                        router.push("/?firstTimeMentor=true");
                        return;
                    }
                    router.push(redirect);
                    return;
                } else {
                    router.push("/");
                    return;
                }
            }
        }
    };

    const handleGoogleLogin = async () => {
        if (!isLoading) {
            setIsLoading(true);

            // Connect user
            await signIn("google");
        }
    };

    useEffect(() => {
        if (queryError) {
            let message = "";
            switch (queryError) {
                case "EMAIL_ALREADY_USED":
                    message = m.login_emailAlreadyUsed();
                    break;
                default:
                    message = m.login_unknowedError();
                    break;
            }

            toast.error(
                queryError == "Callback" ? m.login_unknowedError() : message,
            );
        }
    }, []);

    return (
        <div className="flex items-center justify-center h-screen md:bg-blue-50 md:p-5">
            <section className="md:w-[450px] w-full rounded-xl md:border p-10 md:background-blur-white">
                <Link href="/">
                    <Logo />
                </Link>

                {/* Title */}
                <h1 className="font-sourceSans text-3xl dark:text-white mt-4 font-bold text-left">
                    {firstName ? (
                        <>{m.welcome_back({ firstName })}</>
                    ) : (
                        m.login_title()
                    )}
                </h1>
                <p className="mt-1">{m.login_headline()}</p>
                {/* Sign up with google  */}
                <div className="mt-4">
                    <Button type="google" block onClick={handleGoogleLogin}>
                        <div className="flex items-center justify-between gap-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="0.98em"
                                height="1em"
                                viewBox="0 0 256 262"
                            >
                                <path
                                    fill="#4285F4"
                                    d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622l38.755 30.023l2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                                ></path>
                                <path
                                    fill="#34A853"
                                    d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055c-34.523 0-63.824-22.773-74.269-54.25l-1.531.13l-40.298 31.187l-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                                ></path>
                                <path
                                    fill="#FBBC05"
                                    d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82c0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602z"
                                ></path>
                                <path
                                    fill="#EB4335"
                                    d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0C79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                                ></path>
                            </svg>
                            {m.login_google()}
                        </div>
                    </Button>
                </div>

                <div className="mt-4 flex items-center gap-3">
                    <div className="w-1/2 h-[1px] bg-gray-300"></div>
                    <div className="text-gray-400 dark:text-gray-400 text-sm">
                        {m.login_else()}
                    </div>
                    <div className="w-1/2 h-[1px] bg-gray-300"></div>
                </div>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                    <p>
                        <label htmlFor="email" className="label">
                            {m.login_email()}
                        </label>
                        <input
                            type="email"
                            id="email"
                            placeholder={m.login_email()}
                            className="input"
                            {...register("email", {
                                required: true,
                                pattern:
                                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                        />
                        {errors.email && errors.email.type === "required" && (
                            <small className="text-red-500">
                                {m.login_email_required()}
                            </small>
                        )}
                        {errors.email && errors.email.type === "pattern" && (
                            <small className="text-red-500">
                                {m.login_email_invalid()}
                            </small>
                        )}
                    </p>
                    <p>
                        <label htmlFor="password" className="label">
                            {m.login_password()}
                        </label>
                        <input
                            type="password"
                            id="password"
                            placeholder={m.login_password()}
                            className="input"
                            {...register("password", {
                                required: true,
                            })}
                        />
                        {errors.password && (
                            <small className="text-red-500">
                                {m.login_password_required()}
                            </small>
                        )}
                    </p>
                    <div className="flex justify-end mt-5">
                        <Button isLoading={isLoading} block>
                            {m.login_submit()}
                        </Button>
                    </div>
                </form>
                <div className="mt-5">
                    <Link
                        href="/security/forgotten-password"
                        className="text-gray-500 text-sm"
                    >
                        {m.login_forgottenPassword()}
                    </Link>
                </div>

                {/* Other */}
                <div className="mt-4 flex items-center gap-3">
                    <div className="w-1/2 h-[1px] bg-gray-300"></div>
                    <div className="text-gray-400 dark:text-gray-400 text-sm">
                        {m.login_or()}
                    </div>
                    <div className="w-1/2 h-[1px] bg-gray-300"></div>
                </div>

                <div className="mt-4 text-center">
                    <Link href="/signup" className="text-black text-sm">
                        {m.login_noAccount()}{" "}
                        <b>{m.login_createYourAccount()}</b>
                    </Link>
                </div>
            </section>
        </div>
    );
}
